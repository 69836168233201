<template>
	<div class="page">
		<div class="tree">
			<el-input size="small" placeholder="请输入公众号名称"></el-input>
			<el-tree style="margin-top: 20px;" :data="treeData" :props="defaultProps" @node-click="handleNodeClick" />
		</div>
		<div ref="tableBox" style="flex-grow: 1; background-color: white;">
			<div>
				<el-form :inline="true" size="mini">
					<el-form-item label="用户名称">
						<el-input></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<el-input></el-input>
					</el-form-item>
					<el-form-item label="状态">
						<el-input></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div>
				<span style="margin-right: 20px;">{{nowMerchant}}</span>
				<el-button @click="dialog.add = true" style="margin-bottom: 10px;" type="primary" plain size="mini">+ 新增
				</el-button>
			</div>
			<el-table size="small" header-cell-class-name="tableHeader" :data="listData" border
				:max-height="tableHeight" style="width: 100%;">
				<el-table-column fixed prop="id" label="用户编号" width="80">
				</el-table-column>
				<el-table-column prop="userNick" label="用户名称" width="200">
				</el-table-column>
				<el-table-column label="角色" width="120">
					<template #default="scope">
						<el-tag>{{scope.row.role.title}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号码">
				</el-table-column>
				<el-table-column label="状态" width="180">
					<template #default="scope">
						<el-switch v-model="scope.row.state" :inline-prompt="false" :active-value="1"
							:inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="启用"
							inactive-text="禁用" />
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template #default="scope">
						<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
						<el-button @click="deleteDevice(scope.row)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; text-align: right;">
				<el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
			</div>
		</div>

		<el-dialog v-model="dialog.add" title="添加用户" width="50%" top="5vh">
			<el-form size="mini" :inline="true" label-width="100px">
				<el-form-item style="width: 40%;" label="姓名">
					<el-input autocomplete="new-password" v-model="user.userNick"></el-input>
				</el-form-item>
				<el-form-item style="width: 40%;" label="账号">
					<el-input autocomplete="new-password" v-model="user.username"></el-input>
				</el-form-item>
				<el-form-item style="width: 40%;" label="密码">
					<el-input type="password" autocomplete="new-password" v-model="user.password"></el-input>
				</el-form-item>
				<el-form-item style="width: 40%;" label="手机号">
					<el-input v-model="user.phone"></el-input>
				</el-form-item>
				<el-form-item style="width: 40%;" label="公众号">
					<el-select v-model="user.merchantId" placeholder="Select">
						<el-option v-for="item in treeData" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="width: 40%;" label="岗位">
					<el-select v-model="user.roleId" placeholder="Select">
						<el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="postUser" plain type="primary" size="small">
						添加
					</el-button>
					<el-button plain size="small">
						取消
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list
	} from '../../api/merchant.js'
	import {
		getRoles,
	} from "../../api/SystemApi.js"
	import {
		addUser,
		getUserByMerchant
	} from "../../api/user.js"
	export default {
		data() {
			return {
				treeData: [],
				selectList: [],
				user: {},
				dialog: {
					add: false
				},
				merchantId:1,
				start:0,
				end:60,
				listData:[],
				nowMerchant:""
			}
		},

		mounted() {
			var that = this
			list(0, 999).then(res => {
				let data = res.data.data
				let arr = []
				for (let i = 0; i < data.length; i++) {
					arr.push({
						label: data[i].beizhu,
						value: data[i].client_id
					})
				}
				that.merchantId = arr[0].value
				that.nowMerchant = arr[0].label
				that.treeData = arr
			})

			this.getRoleList()
			this.getList()
			
		},

		methods: {
			getRoleList: function() {
				var that = this
				getRoles().then(res => {
					let data = res.data.data
					let arr = []
					for (let i = 0; i < data.length; i++) {
						arr.push({
							value: data[i].id,
							label: data[i].title
						})
					}
					that.selectList = arr
				})
			},
			
			postUser:function(){
				addUser(this.user).then(res=>{
					console.log(res)
				})
			},
			
			
			getList:function(){
				var that = this
				getUserByMerchant(this.merchantId,this.start,this.end).then(res=>{
					that.listData = res.data.data
				})
			},
			
			handleNodeClick:function(data){
				this.merchantId = data.value
				this.nowMerchant = data.label
				this.getList()
			}
		}
	}
</script>

<style scoped="scoped">
	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		height: 100%;
	}

	.tree {
		width: 200px;
		padding-right: 10px;
	}
</style>
