import request from '../util/requset.js'

export function getMenuList(){
	return request.get("/system/getMenuList")
}

/**
 * 添加角色
 */
export function addRole(data){
	return request.post("/system/addRole",data)
}

export function getRoles(){
	return request.get("/system/getRoles")
}

export function editRole(data){
	return request.post("/system/editRole",data)
}